import * as React from "react"
import Layout from "../layouts/default"
import Seo from "../components/seo"
import * as style from "../sass/pages/clients.module.sass"
import { graphql } from "gatsby"
import { GatsbyImage } from 'gatsby-plugin-image'
import { PortableText } from '@portabletext/react'


const ClientsPage = ({ data }) => {
  const page = data.allSanityClients.edges[0].node;
  return (
    <Layout>
      <Seo title="Clients" />
      <section className={style.mainContent}>
        <PortableText
          value={page._rawHeader}

        />
        <PortableText
          value={page._rawBody}
        />
        <h3>UNITED STATES</h3>
        {
          page.usClients.map((client, index) => (
            <div key={client._key} className={style.client}>
              <h2 className="h4">{client.clientName}</h2>
              <h2 className="h4">{client.location}</h2>
            </div>
          ))
        }
        <h3>CANADA</h3>
        {
          page.canadaClients.map((client, index) => (
            <div key={client._key} className={style.client}>
              <h2 className="h4">{client.clientName}</h2>
              <h2 className="h4">{client.location}</h2>
            </div>
          ))
        }
        <h3>EUROPE</h3>
        {
          page.euClients.map((client, index) => (
            <div key={client._key} className={style.client}>
              <h2 className="h4">{client.clientName}</h2>
              <h2 className="h4">{client.location}</h2>
            </div>
          ))
        }
      </section>
      <section className={style.gallery}>
        <div className={style.imageWrapper}>
          <GatsbyImage className={style.image} image={page.imageLeft.asset.gatsbyImageData} alt={page.imageLeft.alt} />
          <div className={page.imageLeft.luminosity === true ? style.metadata + ' ' + style.light : style.metadata}>
            <p className="caption captionBold">{page.imageLeft.title}</p>
            <p className="caption">{page.imageLeft.subtitle}</p>
          </div>
        </div>
        <div className={style.imageWrapper}>
          <GatsbyImage className={style.image} image={page.imageRight.asset.gatsbyImageData} alt={page.imageRight.alt} />
          <div className={page.imageRight.luminosity === true ? style.metadata + ' ' + style.light : style.metadata}>
            <p className="caption captionBold">{page.imageRight.title}</p>
            <p className="caption">{page.imageRight.subtitle}</p>
          </div>
        </div>
        <div className={style.imageWrapper}>
          <GatsbyImage className={style.image} image={page.imageFull.asset.gatsbyImageData} alt={page.imageFull.alt} />
          <div className={page.imageFull.luminosity === true ? style.metadata + ' ' + style.light : style.metadata}>
            <p className="caption captionBold">{page.imageFull.title}</p>
            <p className="caption">{page.imageFull.subtitle}</p>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default ClientsPage


export const query = graphql`
{
  allSanityClients {
    edges {
      node {
        _rawHeader
        canadaClients {
          _key
          clientName
          location
        }
        euClients {
          _key
          clientName
          location
        }
        usClients {
          _key
          clientName
          location
        }
        imageLeft {
          title
          subtitle
          alt
          luminosity
          asset {
            gatsbyImageData(
              width: 1600
              placeholder: BLURRED
            )
          }
        }
        imageRight {
          title
          subtitle
          alt
          luminosity
          asset {
            gatsbyImageData(
              width: 1600
              placeholder: BLURRED
            )
          }
        }
        imageFull {
          title
          subtitle
          alt
          luminosity
          asset {
            gatsbyImageData(
              width: 1600
              placeholder: BLURRED
            )
          }
        }
      }
    }
  }
}
`